import '../css/notice.css'
import { Link } from "react-router-dom"

function Notice02(){
    return(
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>공지사항</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link1'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link1'>가이드</Link>
                </div>
            </div>
            <div className='notice-contents'>
                <div className='wrap'>
                    <div className='notice-contents-view'>
                        <div className='notice-contents-wrap'>
                            <div className='notice-contents-title'>
                                <p>📢 시스템 작업에 따른 서비스 일시중지 안내</p>
                                <span>관리자</span>
                                <span>2023-08-11 15:12</span>
                            </div>
                            <div className='notice-contents-con'>
                                <img src='img/notice.3.1.png' style={{width:'950px'}}/>
                            </div>
                        </div>
                        <div className='notice-btn'>
                            <Link to={`/notice`} style={{textDecoration:"none"}} className='notice-btn-violet'>목록으로</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice02;