import '../css/notice.css'
import { Link } from "react-router-dom"

function NoticeGuide01() {
    return (
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>가이드</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link1'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link1'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link'>가이드</Link>
                </div>
            </div>
            <div className='notice-contents'>
                <div className='wrap'>
                    <div className='notice-contents-view'>
                        <div className='notice-contents-wrap'>
                            <div className='notice-contents-title'>
                                <p>MHPAY 사용자 이용 가이드</p>
                                <span>관리자</span>
                                <span>2023-08-08 17:30</span>
                            </div>
                            <div className='notice-contents-con'>
                                <p>
                                    안녕하세요.<br />
                                    MHPAY의 사용자 이용 가이드입니다.<br />
                                </p>
                                <img src='img/guide.1.1.png' className='guide_img'/>
                                <img src='img/guide.1.2.png' className='guide_img' />
                                <img src='img/guide.1.3.png' className='guide_img' />
                                <img src='img/guide.1.4.png' className='guide_img' />
                                <img src='img/guide.1.5.png' className='guide_img' />
                                <img src='img/guide.1.6.png' className='guide_img1' />
                            </div>
                        </div>
                        <div className='notice-btn'>
                            <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='notice-btn-violet'>목록으로</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoticeGuide01;