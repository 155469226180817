import './css/guide.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";

function Guide() {
    return (
        <div className='guide'>
            <div className='guide-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>이용안내</h2>
            </div>
            <div className='guide-contents'>
                <div className='wrap'>
                    <div className='guide-wrap'>
                        <h3>
                            MHPAY는 <strong>이렇게 운영됩니다.</strong>
                        </h3>
                        <ul>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >1. 시작하기</h4>
                                <ul className='guide-txtbox'>
                                    <li>앱보다 더 좋은 웹앱으로 이용 </li>
                                    <li>안드로이드, 아이폰, 태블릿, PC 모두 사용 가능</li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >2. 회원가입</h4>
                                <ul className='guide-txtbox'>
                                    <li>기본적으로는 회원제로 운영</li>
                                    <li>회원가입비나 월회비, 연회비등 어떠한 비용도 청구하지 않는 100% 무료 </li>
                                    <li>회원 가입시 기본 가입 축하금으로 1,000원의 마일리지가 지급 </li>
                                    <li>구매시 상품별로 적용된 마일리지는 1,000원 이상이면 사용 가능 </li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >3. 마일리지제도</h4>
                                <ul className='guide-txtbox'>
                                    <li>마일리지를 사용 가능</li>
                                    <li>마일리지 100점은 현금 100원</li>
                                    <li>마일리지는 1,000원 이상되어야 사용하실 수가 있고 10,000원 이 넘는 금액의 적립금은 사용 불가능</li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >4. 상품주문</h4>
                                <ul className='guide-txtbox'>
                                    <li>상품 검색</li>
                                    <li>쇼핑백에 담기</li>
                                    <li>회원ID 로그인 또는 비회원 주문</li>
                                    <li>주문서 작성</li>
                                    <li>결제방법 선택 및 결제</li>
                                    <li>주문 성공 화면 (주문번호)</li>
                                    <li>비회원 주문인 경우 주문번호와 승인번호(카드결제시)를 꼭 메모</li>
                                    <li><strong class="point">※ 단, 회원인 경우 자동 저장되므로 따로 관리하실 필요가 없습니다.</strong></li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >5. 주문확인 및 배송조회</h4>
                                <ul className='guide-txtbox'>
                                    <li>쇼핑몰에서 주문을 했을 경우, 주문/배송 확인을 통해서 확인 가능</li>
                                    <li>회원일 경우 ID와 비밀번호를 입력, 비회원으로 주문했을 경우 입력했던 메일을 입력 <br />
                                        (주문시 입력했던 이름을 정확하게 입력하셔야 합니다. 주문자의 이름을 입력하는 것은 본인 확인을 위해서 입니다.)</li>
                                    <li>현재 배송은 대한통운택배 서비스를 이용하고 있으며, 상품 추적을 통해 실시간으로 추적 가능</li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >6. 안전한 대금 결제</h4>
                                <ul className='guide-txtbox'>
                                    <li>무통장 입금은 상품 구매 대금은 직접 입금, 신용카드 결제는 MHPAY PG사의 전자결제를 이용, 이용내역서에는 MHPAY에서 기록</li>
                                    <li>국내발행 모든 신용카드</li>
                                    <li>해외 발생 신용카드(VISA Card, MASTER Card, AMEX Card)</li>
                                    <li>무통장 입금 가능</li>
                                    <li><strong class="point">※ 신용카드 결제는 MHPAY PG사의 전자결제를 이용하므로 보안문제는 걱정하지 않으셔도 됩니다.</strong></li>
                                    <li><strong class="point">※ 무통장 입금시의 송금자 이름은 주문시 입력하신 주문자의 실명이어야 합니다.</strong></li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >7. 배송기간 및 배송방법</h4>
                                <ul className='guide-txtbox'>
                                    <li>배송날로부터 2-3일 이내에(최장 7일이내) 입력하신 배송처로 주문상품이 도착</li>
                                    <li>주문하실 때 희망 배송일자를 넉넉히 잡으면 원하시는 날짜에 배송</li>
                                </ul>
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                                <FontAwesomeIcon icon={faCircleDot} className='guide-icon-dot' />
                                <h4 >8. 주문취소, 교환 및 환불</h4>
                                <ul className='guide-txtbox'>
                                    <li>쇼핑몰은 소비자의보호를 위해서 규정한 제반 법규 준수</li>
                                    <li>주문 취소는 미결재인 상태에서는 고객이 직접 취소, 결제후 취소는 고객센터로 문의</li>
                                    <li>무통장 입금의 경우 일정기간동안 송금을 하지 않으면 자동 주문 취소, 구매자가 원하는 경우 인터넷에서 바로 취소, 송금을 하신 경우에는 환불</li>
                                    <li>반송을 하실 때 주문번호, 회원번호를 보내면 신속한 처리</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guide;