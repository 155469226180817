import './App.css';
import { Routes, Route } from 'react-router-dom'
import Main from './Pages/main'
import Header from './Nav/header'
import Footer from './Nav/footer'
import Guide from './Pages/guide'
import QnaHot from './Pages/qna/qnaHot'
import Notice from './Pages/notice/notice'
import NoticeGuide from './Pages/noticeGuide/noticeGuide'
import Term from './Pages/term'
import Privacy from './Pages/privacy'
import Agree from './Pages/agree'
import Notice01 from './Pages/notice/notice01'
import Notice02 from './Pages/notice/notice02'
import Notice03 from './Pages/notice/notice03'
import NoticeGuide01 from './Pages/noticeGuide/noticeGuide01'
import NoticeGuide02 from './Pages/noticeGuide/noticeGuide02'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/qnaHot" element={<QnaHot />} />
        <Route path="/notice" element={<Notice />} />
        <Route path="/noticeGuide" element={<NoticeGuide />} />
        <Route path="/term" element={<Term />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/agree" element={<Agree />} />
        <Route path="/notice01" element={<Notice01 />} />
        <Route path="/notice02" element={<Notice02 />} />
        <Route path="/notice03" element={<Notice03 />} />
        <Route path="/noticeGuide01" element={<NoticeGuide01 />} />
        <Route path="/noticeGuide02" element={<NoticeGuide02 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
