import '../css/board.css'
import { Link } from "react-router-dom"

function NoticeGuide() {
    return (
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>가이드</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link1'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link1'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link'>가이드</Link>
                </div>
            </div>
            <div className='notice-contents'>
                <div className='wrap'>
                    <div className='notice-1st'>
                        <div className='notice-tb'>
                            <div className='notice-row hd-group'>
                                <div className='notice-cell col1'>번호</div>
                                <div className='notice-cell col2'>제목</div>
                                <div className='notice-cell col3'>작성자</div>
                                <div className='notice-cell col4'>작성일</div>
                            </div>
                            <NoticeGuideItem />
                        </div>
                    </div>
                    <div className='notice-paging'>
                        <span>1</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function NoticeGuideItem() {
    return (
        <>
        <Link to={`/noticeGuide02`} style={{ textDecoration: "none" }} className='notice-row' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
            <div className='notice-cell col1'>2</div>
            <div className='notice-cell col2'>MHPAY 판매자 이용 가이드</div>
            <div className='notice-cell col3'>관리자</div>
            <div className='notice-cell col4'>2023-08-14 10:17</div>
        </Link>
        <Link to={`/noticeGuide01`} style={{ textDecoration: "none" }} className='notice-row' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
            <div className='notice-cell col1'>1</div>
            <div className='notice-cell col2'>MHPAY 사용자 이용 가이드</div>
            <div className='notice-cell col3'>관리자</div>
            <div className='notice-cell col4'>2023-08-08 17:30</div>
        </Link>
        </>
    )
}

export default NoticeGuide;