import './css/term.css'
import { Link } from "react-router-dom"

function Privacy() {
    return (
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>개인정보 처리방침</h2>
            </div>
            <div className='privacy-tab'>
                <div className='wrap'>
                    <Link to={`/privacy`} style={{ textDecoration: "none" }} className='board-link'>개인정보 처리방침</Link>
                    <Link to={`/agree`} style={{ textDecoration: "none" }} className='board-link1'>개인정보 제3자 제공</Link>
                </div>
            </div>
            <div className='privacy-contents'>
                <div className='wrap'>
                    <div class="terms-wrap">
                        <ul>
                            <li>주식회사 스타뱅크(이하 “회사”)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스 제공자가 준수하여야 할 법령 상의 개인정보 보호 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.</li>
                        </ul>
                        <h3>1. 수집하는 개인정보</h3>
                        <ul>
                            <li>회사는 서비스의 회원가입, 서비스 이용, 원활한 고객상담 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</li>
                            <li>가. 회원가입 시점에 수집하는 개인정보의 항목</li>
                            <li>- 비사업자 회원 : 회원 아이디, 비밀번호, 이름, 휴대폰번호, 이메일</li>
                            <li>- 사업자 회원 : 회원 아이디, 비밀번호, 사업자명, 대표자명, 사업자등록번호, 담당자명, 담당자 휴대폰번호</li>
                            <li>나. 서비스 이용과정에서 수집하는 개인정보의 항목</li>
                            <li>- 주소, 계약상의 입금계좌 정보(은행명/계좌번호/예금주), 입금자명</li>
                            <li>- 계약/거래를 입증할 수 있는 증빙 자료 : 계약서 등	</li>
                            <li>다. 서비스 이용 중 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</li>
                            <li>- 결제 서비스 이용 중 생성되는 정보</li>
                            <li>- IP 주소, 쿠키, 방문일시, 서비스 이용 기록, 불량 이용 기록, 기기정보</li>
                            <li>- 고객 상담 중 생성되는 정보</li>
                            <li>라. 회사는 이용자의 금융거래 및 신용카드 부정사용 발생 우려가 있는 민감한 금융정보는 어떤 경로로도 수집하지 않습니다.</li>
                        </ul>
                        <h3>2. 개인정보 수집방법</h3>
                        <ul>
                            <li>회사는 온라인(모바일 앱 및 홈페이지)을 통해 아래의 방법으로 개인정보를 수집합니다.</li>
                            <li>가. 이용자가 직접 입력한 정보에 대한 개인정보 내역 수집</li>
                            <li>- 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.</li>
                            <li>- 고객센터를 통한 상담, 오프라인 이벤트, 세미나 등에서 웹페이지, 메일, 전화, 서면, 기타 자체 정보수집툴을 통해 개인정보가 수집될 수 있으며, 기기정보와 같은 생성정보가 모바일 앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.</li>
                        </ul>
                        <h3>3. 개인정보의 수집 및 이용목적</h3>
                        <ul>
                            <li>회사는 이용자의 개인정보를 아래와 같은 목적으로 이용하며, 목적이 변경되는 경우 사전에 반드시 이용자의 동의를 받겠습니다.</li>
                            <li>가. 회원 가입 의사의 확인, 이용자 식별, 불량회원 부정이용 방지, 회원탈퇴 의사의 확인 등 회원관리</li>
                            <li>나. 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달</li>
                            <li>다. 신규 서비스 개발, 이벤트 행사 시 정보 전달, 마케팅 및 광고 등에 활용</li>
                            <li>라. 서비스 이용 기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 맞춤형 서비스 제공, 서비스 개선에 활용</li>
                        </ul>
                        <h3>4. 개인정보의 제공 및 위탁</h3>
                        <ul>
                            <li>회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다.</li>
                            <li>다만, 서비스의 원활한 제공을 위해 필요한 때에는 개인정보의 처리를 일부 위탁하고 있으며, 수탁 받은 업체가 관계 법령을 준수하도록 관리ㆍ감독하고 있습니다.</li>
                            <li>
                                <table>
                                    <tbody><tr>
                                        <th>수탁업체</th>
                                        <th>위탁업무</th>
                                    </tr>
                                        <tr>
                                            <td>주식회사 스타뱅크</td>
                                            <td>
                                                MHPAY 서비스 결제대행 및 정산대행 (결제 및 취소 처리, 승인된 계약 결제건의 정산송금, 결제 도용 방지, 간편결제 서비스 제공 및 운영, 부정행위 방지)
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </li>
                            <li>
                                또한, 이용자가 외부 제휴 서비스를 이용하기 위하여 개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해 회사에 개인정보 제출 의무가 발생한 경우에 한하여 개인정보를 제공하고 있습니다.
                            </li>
                        </ul>
                        <h3>5. 개인정보의 보유 및 이용기간</h3>
                        <ul>
                            <li>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.</li>
                            <li>다만, 관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다.</li>
                            <li>이때 별도 저장 관리되는 개인정보는 법령에 정한 경우 외에는 절대 다른 용도로 이용되지 않습니다.</li>
                        </ul>
                        <h3>6. 개인정보 파기절차 및 방법</h3>
                        <ul>
                            <li>이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</li>
                            <li>가. 파기절차</li>
                            <li>- 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.</li>
                            <li>- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</li>
                            <li>나. 파기방법</li>
                            <li>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다.</li>
                            <li>- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
                        </ul>
                        <h3>7. 이용자의 권리와 그 행사방법</h3>
                        <ul>
                            <li>가. 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회, 수정 또는 가입 해지를 요청할 수 있습니다.</li>
                            <li>나. 이용자의 개인정보에 대한 조회, 수정 또는 가입 해지를 위해서 개인정보 관리책임자 혹은 개인정보 관리담당자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.</li>
                            <li>다. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
                            <li>라. 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 5. 개인정보의 보유 및 이용기간에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
                        </ul>
                        <h3>8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
                        <ul>
                            <li>가. 쿠키란</li>
                            <li>- 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.</li>
                            <li>- 쿠키는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터에 저장됩니다. 이후 이용자가 서비스를 이용할 경우 서버는 이용자의 컴퓨터에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.</li>
                            <li>- 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.</li>
                            <li>나. 쿠키의 사용 목적</li>
                            <li>이용자들의 방문 및 이용형태, 이용자 규모 등을 파악하여 이용자에게 최적화된 서비스 제공을 위해 사용합니다.</li>
                            <li>다. 쿠키의 설치/운영 및 거부</li>
                            <li>- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 모든 쿠키를 허용하거나, 쿠키가 ㄷ저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</li>
                            <li>- 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스는 이용에 어려움이 있을 수 있습니다.</li>
                        </ul>
                        <h3>9. 개인정보의 기술적/관리적 보호대책</h3>
                        <ul>
                            <li>회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</li>
                            <li>가. 비밀번호 암호화</li>
                            <li>회원 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.</li>
                            <li>나. 해킹 등에 대비한 대책</li>
                            <li>회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</li>
                            <li>다. 취급 직원의 최소화 및 교육</li>
                            <li>회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고, 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.</li>
                            <li>라. 개인정보보호전담기구의 운영</li>
                            <li>사내 개인정보보호전담기구 등을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷 상의 문제로 아이디(ID), 비밀번호, 이메일 주소 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.</li>
                        </ul>
                        <h3>10. 개인정보보호 책임자 및 담당부서</h3>
                        <ul>
                            <li>회사는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보보호 책임자를 지정하고 운영하고 있습니다.</li>
                            <li>개인정보보호 책임자 및 담당부서</li>
                            <li>이름 : 박소영</li>
                            <li>담당부서 : 핀테크사업팀</li>
                            <li>전화 : 02-531-1592</li>
                            <li>메일 : so00905@naver.com</li>
                            <li>기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.</li>
                            <li>- 개인정보침해신고센터 (<a href="https://privacy.kisa.or.kr" target="_blank" title="새창열림">privacy.kisa.or.kr</a> / 국번없이 118)</li>
                            <li>- 대검찰청 사이버수사과 (<a href="https://www.spo.go.kr" target="_blank" title="새창열림">www.spo.go.kr</a> / 국번없이 1301)</li>
                            <li>- 경찰청 사이버테러대응센터 (<a href="https://www.police.go.kr/www/security/cyber.jsp" target="_blank" title="새창열림">www.police.go.kr</a> / 국번없이 182)</li>
                        </ul>
                        <h3>11. 본 개인정보처리방침의 범위</h3>
                        <ul>
                            <li>본 개인정보처리방침은 회사의 브랜드 중 하나인 MHPAY 서비스에 적용되며, 다른 브랜드로 제공되는 서비스에 대해서는 별개의 개인정보처리방침이 적용될 수 있습니다.</li>
                        </ul>
                        <h3>12. 개정 전 고지 의무</h3>
                        <ul>
                            <li>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 공지사항을 통해 사전 공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.</li>
                            <li>- 개인정보처리방침 공고일자 : 2023년 8월 8일</li>
                            <li>- 개인정보처리방침 시행일자 : 2023년 8월 8일</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;