import './css/main.css'
import { Link } from "react-router-dom"
import AOS from "aos";
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { useMediaQuery } from 'react-responsive';

function Main() {
    const Desktop = ({ children }) => {
        const isDesktop = useMediaQuery({ minWidth: 1200 })
        return isDesktop ? children : null
    }

    const Mobile = ({ children }) => {
        const isMobile = useMediaQuery({ maxWidth: 1199 })
        return isMobile ? children : null
    }

    SwiperCore.use([Navigation]);

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <div className='main'>
            <section className='section1'>
                <div className='wrap'>
                    <div className='main-txbox'>
                        <div className="main-txbox-tit" data-aos="fade-down" data-aos-duration="1000" data-aos-once="true">
                            안전한 전자금융<br />
                            <strong>MHPAY가 함께합니다.</strong>
                        </div>
                        <div className="main-txbox-tag" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="200" data-aos-once="true">
                            <em>2회~12회 분할 정산</em>
                            <em>결제-환불 분쟁 방지</em>
                            <em>장기 간 유예 지급 가능</em>
                        </div>
                        <p className='main-txbox-p' data-aos="fade-down" data-aos-duration="1000" data-aos-delay="500" data-aos-once="true">
                            B2B 거래에서 활용되어온 에스크로 방식을 차용하고, <br />
                            기존의 PG 정산-지급 솔루션을 차별화·고도화하여<br />
                            자체 기술로 구현된 분할 정산 솔루션을 제공합니다.
                        </p>
                        <Desktop><a href="http://www.mhmall.mhpay.kr/" target="_blank" class="main-txbox-btn"
                            data-aos="fade-down" data-aos-duration="1000" data-aos-delay="700" data-aos-once="true">직접 경험해보기</a></Desktop>
                        <Mobile><a href="http://m.mhpay.kr/" target="_blank" class="main-txbox-btn"
                            data-aos="fade-down" data-aos-duration="1000" data-aos-delay="700" data-aos-once="true">직접 경험해보기</a></Mobile>
                        {/* <a href="http://www.mhmall.mhpay.kr/" target="_blank" class="main-txbox-btn"
                            data-aos="fade-down" data-aos-duration="1000" data-aos-delay="700" data-aos-once="true">직접 경험해보기</a> */}
                    </div>
                    <div className='main-imgbox'>
                        <img src='img/mockup_2.png' className='main_img' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section2'>
                <div className='wrap'>
                    <div className='sec2-txtbox' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec2-txtbox-a'>
                            <span>01</span>
                            <strong>MHPAY는 플랫폼의 요청에 맞게<br />
                                분할하여 정산할 수 있도록 지원합니다.</strong>
                        </div>
                        <p>
                            새로운 정산 솔루션은 거래 당사자 또는 플랫폼의 요구에 따라<br />
                            <strong>정산일을 적기로 조정할 수 있습니다.</strong><br />
                        </p>
                        <p>
                            또한 고객의 요구 상황에 맞춰 정산 횟수를<br />
                            <strong>2~12차례 이상으로 분할</strong>이 가능합니다.<br />
                        </p>
                    </div>
                    <div className='sec2-imgbox'>
                        <img src='img/icon_1.png' className='sec2-img' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section3'>
                <div className='wrap'>
                    <div className='sec3-txtbox' data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec3-txtbox-a'>
                            <span>02</span>
                            <strong>장기 간 유예 지급이 가능합니다.</strong>
                        </div>
                        <p>
                            카드사 마다 다른 취소 기간(60일~1년)과<br />
                            무관하게 <strong>장기 간 유예 지급</strong>이 가능합니다.
                        </p>
                    </div>
                    <div className='sec3-imgbox'>
                        <img src='img/icon_2.png' className='sec3-img' style={{ paddingTop: "10px" }}
                            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section2'>
                <div className='wrap'>
                    <div className='sec2-txtbox' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec2-txtbox-a'>
                            <span>03</span>
                            <strong>플랫폼 내 결제-환불 분쟁을 <br />
                                방지할 수 있습니다.</strong>
                        </div>
                        <p>
                            플랫폼 내 결제-환불 분쟁이 발생했을 때,<br />
                            판매자 혹은 구매자가 일방적인 불편을 감수하게 됩니다.<br />
                        </p>
                        <p>
                            문제를 해결하고자 <strong>PG사가 법적으로 부여받은</strong><br />
                            <strong>카드 결제금액의 정산 대행의 역할</strong>을 적극적으로 활용하였습니다.
                        </p>
                    </div>
                    <div className='sec2-imgbox'>
                        <img src='img/icon_3.png' className='sec2-img' style={{ paddingTop: "1vw" }}
                            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section3'>
                <div className='wrap'>
                    <div className='sec3-txtbox' data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec3-txtbox-a'>
                            <span>04</span>
                            <strong>문제가 발생할 경우 회수가 용이합니다.</strong>
                        </div>
                        <p>
                            전체 결제금이 플랫폼과 최종 판매자에게 지급되지 않으므로,<br />
                            중간에 문제가 발생할 경우 회수가 용이하며, <br />
                            따라서 <strong>소비자는 안심하고 선결제에 동의</strong>할 수 있습니다.
                        </p>
                    </div>
                    <div className='sec3-imgbox'>
                        <img src='img/icon_4.png' className='sec3-img' style={{ paddingLeft: "10px" }}
                            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section2'>
                <div className='wrap'>
                    <div className='sec2-txtbox' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec2-txtbox-a'>
                            <span>05</span>
                            MHPAY 서비스 이용료가 <br />
                            <strong>타사대비 저렴한 3.2%</strong>입니다.
                        </div>
                        <p>
                            <strong>서비스 이용료 3.2%(부가세포함)</strong>로 이용하세요.<br />
                            <strong>(100만원 판매 시 판매자에게 96.8만원 지급)</strong>
                        </p>
                    </div>
                    <div className='sec2-imgbox'>
                        <img src='img/icon_5.png' className='sec2-img' style={{ paddingLeft: "15px" }}
                            data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section3'>
                <div className='wrap'>
                    <div className='sec3-txtbox' data-aos="fade-left" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec3-txtbox-a'>
                            <span>06</span>
                            <strong>1년내내</strong><br />
                            <strong>연중무휴</strong>로 운영됩니다.
                        </div>
                        <p>
                            <strong>주말, 공휴일에도 09~24시 상담지원됩니다.</strong><br />
                            결제는 24시간 내내 가능하며,<br />
                            매일밤 23:30~00:30 1시간을 제외한<br />
                            나머지 모든 시간에 이용 가능합니다.
                        </p>
                    </div>
                    <div className='sec3-imgbox'>
                        <img src='img/icon_6.png' className='sec3-img' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>
            <section className='section2'>
                <div className='wrap'>
                    <div className='sec2-txtbox' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <div className='sec2-txtbox-a'>
                            <span>07</span>
                            <strong>휴대폰, 태블릿, PC</strong><br />
                            모두 사용 가능합니다.
                        </div>
                        <p>
                            모바일 버전 적용으로<br />
                            <strong>기존 웹과 똑같은 기능을 더 빠르고 안정적으로</strong><br />
                            PC와 모든 모바일 기기에서 사용 가능합니다.
                        </p>
                    </div>
                    <div className='sec2-imgbox'>
                        <img src='img/icon_7.png' className='sec2-img' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true"></img>
                    </div>
                </div>
            </section>

            <section className='section4'>
                <div className='wrap'>
                    <div className='sec4-a'><strong>단계별 이용 방법</strong>을 한눈에 살펴보세요.</div>
                    <div className='sec4-link'>
                        <Desktop>
                            <Link to={`/noticeGuide01`} style={{ textDecoration: "none" }} className='sec4-link-a'>사용자 이용 가이드</Link>
                            <Link to={`/noticeGuide02`} style={{ textDecoration: "none" }} className='sec4-link-a'>판매자 이용 가이드</Link>
                        </Desktop>
                        <Mobile>
                            <Link to={`/noticeGuide01`} style={{ textDecoration: "none" }} className='sec4-link-a'>사용자<br />이용 가이드</Link>
                            <Link to={`/noticeGuide02`} style={{ textDecoration: "none" }} className='sec4-link-a'>판매자<br />이용 가이드</Link>
                        </Mobile>
                    </div>
                </div>
            </section>

            <section className='section5'>
                <div className='wrap'>
                    <div className='sec5-txtbox'>MHPAY <strong>미리보기</strong></div>
                    <Desktop>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={1}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='swiper_img'>
                                <img src='img/mockup2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup3_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup5_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup4_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                        </Swiper>
                    </Desktop>
                    <Mobile>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={1}
                            loop={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide className='swiper_img'>
                                <img src='img/mockup2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup3_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup5_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src='img/mockup4_2.png' className='sec5-contents5-img' data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" />
                            </SwiperSlide>
                        </Swiper>
                    </Mobile>

                    <div className='sec5-bg-cloud' style={{ backgroundImage: 'url(/img/bg_sec7_cloud.png)' }}></div>
                    <div className='sec5-bg-raindrop' style={{ backgroundImage: 'url(/img/bg_sec7_raindrop.png)' }}></div>
                </div>
            </section>
            {/* <section className='section6' style={{ backgroundImage: 'url(/img/bg_sec8.png)' }}>
                <div className='wrap'>
                    <div className='sec6-txtbox'>
                        안심하세요<em>!</em><br />
                        MHpay 정산을
                        <span class="sec6-payapp">QWER</span>에서 대행합니다.
                    </div>
                    <p>
                        <span>
                            15만 가맹점, 누적결제금액 4조원, 하루에 30억원 이상 결제되는 페이앱에서 단비페이 정산을 대행합니다.<br />
                            결제금액이 임대인 계좌로 입금되지 않는 사고에 대해 1도 걱정마세요!
                        </span>
                        <strong>&lt;전자지급결제대행업 등록번호 02-004-00096&gt;</strong><br />
                        <strong>&lt;결제대금예치업 등록번호 02-006-00035&gt;</strong>
                    </p>
                </div>
            </section> */}
        </div>
    )
}

export default Main;