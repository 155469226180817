import './nav.css'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faChevronRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';

function Header() {
    const [button, setButton] = useState(false);
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 500) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        }

        window.addEventListener("scroll", handleShowButton)
        return () => {
            window.removeEventListener("scroll", handleShowButton)
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        if (button) {
            document.body.style.cssText = `
            position: fixed; 
            top: -${window.scrollY}px;
            overflow-y: scroll;
            width: 100%;`;
            return () => {
                const scrollY = document.body.style.top;
                document.body.style.cssText = '';
                window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
            };
        }
    }, [button]);

    return (
        <div className='header'>
            <div className='wrap'>
                <h1>
                    <Link to={`/`} style={{ textDecoration: "none"}} className='header-wrap-logo'>
                        <img src="/img/MHpay_logo.png"></img>
                    </Link>
                </h1>
                <div className='header-menu'>
                    <ul>
                        <li className='header-menu-li'>
                            <Link to={`/guide`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text'>이용안내</Link>
                        </li>
                        <li className='header-menu-li'>
                            <Link to={`/qnaHot`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text'>고객센터</Link>
                        </li>
                        <li className='header-menu-li'>
                            <a href='' className='header-menu-li-link' onClick={() => alert('준비중입니다.')}>
                                <img src="/img/kakao.svg" className='header-menu-li-link-kakao'></img>카카오톡 문의</a>
                        </li>
                        <li className='header-menu-li'>
                            <a href='http://www.mhmall.mhpay.kr/' target='_blank' className='header-menu-li-link1'>직접 경험해보기</a>
                        </li>
                    </ul>
                </div>
                <FontAwesomeIcon icon={faBars} className='header-menu-ham' onClick={() => setButton(button => !button)} />
                {
                    button
                        ?
                        <>
                        <div className='toogle-bg' onClick={() => setButton(button => !button)} ></div>
                        <nav className='toggle-nav'>
                            <FontAwesomeIcon icon={faXmark} className='header-menu-x' onClick={() => setButton(button => !button)} />
                                <a href='http://m.mhpay.kr/' target='_blank' className='header-menu-li-link1' onClick={() => setButton(button => !button)} >
                                직접 경험해보기</a>
                            <ul>
                                <li className='header-menu-li'>
                                    <Link to={`/guide`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text' onClick={() => setButton(button => !button)} >
                                        이용안내</Link>
                                    <FontAwesomeIcon icon={faChevronRight} className='header-menu-rightarrow' />
                                </li>
                                <li className='header-menu-li'>
                                    <Link to={`/qnaHot`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text' onClick={() => setButton(button => !button)} >
                                        자주 묻는 질문</Link>
                                    <FontAwesomeIcon icon={faChevronRight} className='header-menu-rightarrow' />
                                </li>
                                <li className='header-menu-li'>
                                    <Link to={`/notice`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text' onClick={() => setButton(button => !button)} >
                                        공지사항</Link>
                                    <FontAwesomeIcon icon={faChevronRight} className='header-menu-rightarrow' />
                                </li>
                                <li className='header-menu-li'>
                                    <Link to={`/noticeGuide`} style={{ textDecoration: "none", color: "black" }} className='header-menu-li-text' onClick={() => setButton(button => !button)} >
                                        가이드</Link>
                                    <FontAwesomeIcon icon={faChevronRight} className='header-menu-rightarrow' />
                                </li>
                                <li className='header-menu-li'>
                                    <a href='' className='header-menu-li-text' onClick={() => alert('준비중입니다.')} >
                                        카카오톡 문의</a>
                                    <FontAwesomeIcon icon={faChevronRight} className='header-menu-rightarrow' />
                                </li>
                            </ul>
                        </nav>
                        </>
                        :
                        ""
                }
            </div>
            {
                showButton &&
                <div className="scroll__container">
                    <div id="top" onClick={scrollToTop} type="button" ><FontAwesomeIcon icon={faArrowUp} /></div>
                </div>
            }
        </div>
    )
}

export default Header;