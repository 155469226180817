import '../css/notice.css'
import { Link } from "react-router-dom"

function Notice02(){
    return(
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>공지사항</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link1'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link1'>가이드</Link>
                </div>
            </div>
            <div className='notice-contents'>
                <div className='wrap'>
                    <div className='notice-contents-view'>
                        <div className='notice-contents-wrap'>
                            <div className='notice-contents-title'>
                                <p>광복절 정상영업합니다.</p>
                                <span>관리자</span>
                                <span>2023-08-11 11:26</span>
                            </div>
                            <div className='notice-contents-con'>
                                <img src='img/8.15.PNG' />
                                <p>
                                <br />
                                광복절 휴일에도 정상영업 합니다.<br/><br/>
                                그리고, 고객센터 운영시간은 09~24시입니다. <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className='notice-btn'>
                            <Link to={`/notice`} style={{textDecoration:"none"}} className='notice-btn-violet'>목록으로</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice02;