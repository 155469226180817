import './css/term.css'
import { Link } from "react-router-dom"

function Agree() {
    return (
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>개인정보 처리방침</h2>
            </div>
            <div className='privacy-tab'>
                <div className='wrap'>
                    <Link to={`/privacy`} style={{ textDecoration: "none" }} className='board-link1'>개인정보 처리방침</Link>
                    <Link to={`/agree`} style={{ textDecoration: "none" }} className='board-link'>개인정보 제3자 제공</Link>
                </div>
            </div>
            <div className='privacy-contents'>
                <div className='wrap'>
                    <div class="terms-wrap">
                        <ul>
                            <li>MHpay 회원 계정으로 MHpay 서비스를 이용하는 경우, 편리하고 안전한 서비스 제공을 위해 아래와 같이 주식회사 유디아이디에 MHpay 개인정보를 제공합니다.</li>
                        </ul>
                        <h3>1. 개인정보를 제공받는 자</h3>
                        <ul>
                            <li>주식회사 스타뱅크 (서비스명 : MHpay)</li>
                        </ul>
                        <h3>2. 제공하는 개인정보 항목</h3>
                        <ul>
                            <li>MHpay User ID, 휴대폰번호, 송금할 계좌정보(은행명/계좌번호/예금주), 고객 상담 내역, 서비스 이용기록 및 부정이용기록, IP 주소, 단말기 정보(OS, 화면사이즈, 기기식별값)</li>
                        </ul>
                        <h3>3. 개인정보를 제공받는 자의 개인정보 이용목적</h3>
                        <ul>
                            <li>- MHpay에서 승인된 계약에 대한 결제건의 결제대행 및 정산대행</li>
                            <li>- 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정 도용 및 부정거래 방지, 약관 개정 등 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영</li>
                            <li>- 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축</li>
                        </ul>
                        <h3>4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간</h3>
                        <ul>
                            <li>- 회원탈퇴 시까지 (단, 관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우에는 해당 수사, 조사 종료시까지 보관하며 내부규정 위반 시에 규정에 따라 일정기간 보관)</li>
                            <li>- MHpay 회원은 위 개인정보 제공 동의를 거부할 권리가 있으나, 위 개인정보 제공 동의 거부 시 MHpay를 이용한 결제 및 송금이 불가능합니다. 그 밖의 내용은 MHpay 개인정보 처리방침을 따릅니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agree;