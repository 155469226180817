import '../css/notice.css'
import { Link } from "react-router-dom"

function Notice01(){
    return(
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>공지사항</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link1'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link1'>가이드</Link>
                </div>
            </div>
            <div className='notice-contents'>
                <div className='wrap'>
                    <div className='notice-contents-view'>
                        <div className='notice-contents-wrap'>
                            <div className='notice-contents-title'>
                                <p>MHPAY가 왔어요 :)</p>
                                <span>관리자</span>
                                <span>2023-08-08 17:30</span>
                            </div>
                            <div className='notice-contents-con'>
                                <p>
                                스타뱅크는 세계최초로 전자어음 시스템을 개발해 2005년 전자어음 기술지원사업자로 지정되었습니다.<br/><br/>
                                전자어음을 통해 B2B거래 방식을 혁신한 경험을 보유하고 있으며, 이에 따라 PG와 결합한 차세대 서비스를 선보이기 위해 준비하고 있습니다.<br/><br/>
                                기존의 결제 솔루션은 단순 쇼핑몰 주문을 결제와 연동하고, 결제와 정산을 통해 1회성 거래에 맞춰져 왔습니다.<br/><br/>
                                스타뱅크의 새로운 결제 솔루션은 거래 요건에 따른 정산 절차를 더해 스타뱅크만의 분할 정산 솔루션을 선보이려고 합니다.<br/><br/>
                                해당 쇼핑몰은 분할 결제 솔루션 도입한 상품을 판매할 수 있도록 하는 스타뱅크의 첫 쇼핑몰이 될 것입니다.<br/><br/><br />
                                스타뱅크는 고객의 입장에서 더욱 편리한 솔루션을 개발할 수 있도록 노력하겠습니다.<br/><br/>
                                감사합니다.
                                </p>
                            </div>
                        </div>
                        <div className='notice-btn'>
                            <Link to={`/notice`} style={{textDecoration:"none"}} className='notice-btn-violet'>목록으로</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice01;