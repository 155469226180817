import '../css/board.css'
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faQ } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';

function QnaHot() {
    return (
        <div className='board'>
            <div className='board-subtitle' style={{ backgroundImage: 'url(/img/bg.jpg)' }}>
                <h2>자주 묻는 질문</h2>
            </div>
            <div className='board-tab'>
                <div className='wrap'>
                    <Link to={`/qnaHot`} style={{ textDecoration: "none" }} className='board-link'>자주 묻는 질문</Link>
                    <Link to={`/notice`} style={{ textDecoration: "none" }} className='board-link1'>공지사항</Link>
                    <Link to={`/noticeGuide`} style={{ textDecoration: "none" }} className='board-link1'>가이드</Link>
                </div>
            </div>
            <div className='board-contents'>
                <div className='wrap'>
                    <div className='board-qna'data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100" data-aos-once="true">
                        <QnAItem />
                    </div>
                </div>
            </div>
        </div>
    )
}

function QnAItem() {
    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)
    const [btn3, setBtn3] = useState(false)
    const [btn4, setBtn4] = useState(false)
    const [btn5, setBtn5] = useState(false)
    const [btn6, setBtn6] = useState(false)
    const [btn7, setBtn7] = useState(false)
    const [btn8, setBtn8] = useState(false)
    const [btn9, setBtn9] = useState(false)
    const [btn10, setBtn10] = useState(false)
    const [btn11, setBtn11] = useState(false)
    const [btn12, setBtn12] = useState(false)


    return (
        <>
            <div className='board-qna-set'>
                <button onClick={() => {
                    setBtn1(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false);
                }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>서비스 이용 가능시간이 어떻게 되죠?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn1
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                1. 결제는 24시간 내내 언제나 이용 가능하세요~ <br />
                                2. 무통장 입금의 경우, 매일 밤 23:30~00:30은 은행별 점검으로 입금이 제한되는 시간대입니다.<br />
                                3. 고객센터는 연중무휴 09~24시 운영됩니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div><div className='board-qna-set'>
                <button onClick={() => { setBtn2(btn => !btn);
                    setBtn1(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>이용 매뉴얼이나 가이드 있나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn2
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                사용자 가이드는 가이드 메뉴1번 <strong>"MHPAY 이용자 가이드"</strong>를 참고해 주세요.<br />
                               판매자 가이드는 가이드 메뉴2번  <strong>"MHPAY 판매자 가이드"</strong>를 참고해 주세요.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn3(btn => !btn);
                    setBtn2(false);
                    setBtn1(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>이게 무슨 서비스에요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn3
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                MHPAY는 PG사로서 사전에 합의된 조건에 따라 중간 정산이 이루어질 수 있도록 하는 솔루션을 제공합니다.<br />
                                MHPAY는 분할 정산 솔루션을 통해 소비자와 판매자가 환불이나 할인 혹은 사후 분쟁의 소지 없이 상호 신뢰와 만족을 유지할 수 있도록 돕게 됩니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn4(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn1(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>PG사(=결제대행사)가 무엇인가요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn4
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                PG사는 20개 가량의 신용카드사와 직접 계약하기 어려운 온라인 쇼핑몰을 대신해 결제와 정산 업무를 대행해 주는 업체를 말합니다. <br />
                                PG사와 계약하면 카드 결제, 계좌이체, 무통장입금 등 다양한 결제 수단을 쇼핑몰 방문 고객에게 제공할 수 있습니다.  <br />
                                결제를 대신해 준다고 해서 ‘결제대행사'라고도 합니다. <br /><br />
                                * PG:Payment Gateway의 약자
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn5(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn1(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>서비스 이용로가 얼마죠?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn5
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                서비스 이용료는 부가세까지 포함해 총 3.2%가 결제됩니다.<br />
                                예를 들어 100만원 결제 시 판매자에게 96.8만원이 지급되며, 3.2만원이 서비스 이용료입니다.<br /><br />
                                MHPAY로 결제하시면 신용카드소득공제 금액에 포함되고, 연말정산 때 공제혜택을 받을 수 있습니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn6(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn1(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>추후에 이용료가 변경될 수 있나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn6
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                정해진 이용료는 변경되지 않습니다.<br />
                                MHPAY는 더 많은 분들께 서비스를 제공하고 싶습니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn7(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn1(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>결제수단은 어떻게 되나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn7
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                카드결제, 무통장입급 ,실시간계좌이체<br />
                                위와 같은 3가지 수단이 준비되어 있습니다.<br />
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn8(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn1(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>무통장 입금 결제시,주문서에 기재한 입금자명과 다른경우 어떻게 하나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn8
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                무통장입금은 신청별로 가상계좌가 부여되기 때문에<br />
                                해당 가상계좌로 요청금액만큼 입금했다면 입금자명과 무관하게 입금 확인이 이루어집니다.<br /><br />
                                입금후(주문서와 입금정보가 같은 경우) 2시간이내로 입금확인처리가 되지 않을 경우,<br />
                                고객센터로 문의부탁드립니다.(문의 시 주문번호,입금금액,날짜,입금자명 기재필수)
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn9(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn1(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>주문 후 결제 수단을 변경할 수 있나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn9
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                결제 완료 후 결제수단 변경은 불가 합니다.<br />
                                주문 취소후에 재주문 해주셔야 합니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn10(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn1(false);
                    setBtn11(false);
                    setBtn12(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>취소 및 환불은 어떻게 하죠?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn10
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                MHPAY은 소비자의보호를 위해서 규정한 제반 법규를 준수합니다.<br />
                                주문 취소는 미결재인 상태에서는 고객님이 직접 취소하실 수가 있습니다. 결제후 취소는 저희 고객센터로 문의해 주시기 바랍니다.<br />
                                무통장 입금의 경우 일정기간동안 송금을 하지 않으면 자동 주문 취소가 되고, 구매자가 원하는 경우 인터넷에서 바로 취소 하실 수도 있으며, 송금을 하신 경우에는 환불조치 해드립니다.<br />
                                카드로 결제하신 경우, 승인 취소가 가능하면 취소을 해드리지만 승인 취소가 불가능한 경우 해당 금액을 모두 송금해 드립니다.<br />
                                반송을 하실 때에는 주문번호, 회원번호를 메모하여 보내주시면 보다 신속한 처리에 도움이 됩니다.
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
            <div className='board-qna-set'>
                <button onClick={() => { setBtn12(btn => !btn);
                    setBtn2(false);
                    setBtn3(false);
                    setBtn4(false);
                    setBtn5(false);
                    setBtn6(false);
                    setBtn7(false);
                    setBtn8(false);
                    setBtn9(false);
                    setBtn10(false);
                    setBtn11(false);
                    setBtn1(false); }}>
                    <FontAwesomeIcon icon={faQ} className='board-qna-icon-Q' />
                    <p>정산은 어떻게 되나요?</p>
                    {/* <FontAwesomeIcon icon={faChevronUp} className='board-qna-icon'/> */}
                    <FontAwesomeIcon icon={faChevronDown} className='board-qna-icon' />
                </button>
                {
                    btn12
                        ?
                        <div className='board-qna-active' data-aos="fade-down" data-aos-duration="1000">
                            <p>
                                정산은 구매자가 승인 후 5~10영업일에 입금 됩니다.<br />
                                정산이 늦어지는 경우, 고객센터로 요청 부탁드립니다.<br />
                            </p>
                        </div>
                        :
                        ""
                }
            </div>
        </>
    )
}

export default QnaHot;